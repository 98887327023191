import axios from "axios";
import scrollMonitor from "scrollMonitor";
import FlatSearchGoogleMaps from "./services/FlatSearchGoogleMaps";
import {FilterBoxes} from "./filter-box";

const ACTIVE_CLASS = "active";
const LOADING_RESULTS_CLASS = "loading-results";
const LOADING_MORE_FLATS = "loading-more-flats";
const LOADING_MORE_FLATS_OFFSET = 600;
const API_URL = "/wp-json/v1/estates_map_search";

export const FlatsMapSearchPage = () => {
    const flatsMainWrapper = document.querySelector(".flats-map-search-page");

    if (flatsMainWrapper) {
        const filterBoxes = document.querySelectorAll(".filter-box-wrapper");
        const mapSearchForm = document.getElementById("map-search-form");
        const typeButtons = document.querySelectorAll(".filter-type-button-wrap");
        const typeButtonsCheckboxes = document.querySelectorAll(".filter-type-button-wrap input");
        const buttonMoreFilters = document.querySelector(".button--more-filters");
        const actionButtons = document.querySelectorAll(".search-box-buttons .button");
        const checkFilterBoxes = document.querySelectorAll(".check-filter-box");
        const resetButton = document.querySelector(".reset-filters");
        const moreFiltersContainer = document.querySelector(".more-filters__list");
        const searchResultsInner = document.querySelector(".search-results-inner");
        const notFoundFlats = flatsMainWrapper.querySelector(".empty-search-results");
        const notFoundMorePages = flatsMainWrapper.querySelector(".no-more-pages");
        const loadMoreFlatsTrigger = document.getElementById("load_more_flats");

        const loadMoreFlatsWatcher = scrollMonitor.create(loadMoreFlatsTrigger, LOADING_MORE_FLATS_OFFSET);

        let postsFetchInProgress = false;
        const flatsSearchMap = new FlatSearchGoogleMaps();
        flatsSearchMap.initMap();

        const boxOptionSelection = (box) => {
            FilterBoxes.onFilterBoxSelection(box, () => {
                toggleFormButtonsActiveState();
            });
        }

        const getSelectedFilters = () => {
            const filters = mapSearchForm.querySelectorAll('[name]');

            let filtersValues = {
                type: [],
                attributes: [],
                location: null,
                location_range: null,
                transaction_type: null,
                market: null,
                rooms_from: null,
                price_from: null,
                price_to: null,
                area_from: null,
                area_to: null
            };

            filters.forEach((filter) => {
                const isCheckbox = filter.type === "checkbox";

                if (filter.value && !isCheckbox || isCheckbox && filter.checked) {
                    switch (filter.name) {
                        case "type[]":
                            filtersValues["type"].push(filter.value);
                            break;
                        case "attributes[]":
                            filtersValues["attributes"].push(filter.value);
                            break;
                        default:
                            filtersValues[filter.name] = filter.value;
                            break;
                    }
                }
            });

            Object.keys(filtersValues).forEach((k) => {
                const item = filtersValues[k];
                if (item === null || item instanceof Array && !item.length) {
                    delete filtersValues[k];
                }
            });

            const isFormFilled = Object.keys(filtersValues).length;
            return isFormFilled ? filtersValues : null;
        }

        const toggleFormButtonsActiveState = () => {
            actionButtons.forEach((button) => {
                button.disabled = false;
            });
        }

        const toggleMoreFilters = () => {
            if (buttonMoreFilters && moreFiltersContainer) {
                buttonMoreFilters.addEventListener("click", () => {
                    const buttonLabel = buttonMoreFilters.querySelector(".label");
                    const lessText = buttonLabel.getAttribute("data-label-less");
                    const moreText = buttonLabel.getAttribute("data-label-more");

                    if (buttonMoreFilters.classList.contains(ACTIVE_CLASS)) {
                        buttonLabel.innerHTML = moreText;
                    } else {
                        buttonLabel.innerHTML = lessText;
                    }

                    buttonMoreFilters.classList.toggle(ACTIVE_CLASS);
                    moreFiltersContainer.classList.toggle(ACTIVE_CLASS);
                });
            }
        }

        const handleTypesSelection = () => {
            typeButtons.forEach((buttonWrap) => {
                const button = buttonWrap.querySelector(".button");

                button.addEventListener("click", () => {
                    button.classList.toggle(ACTIVE_CLASS);
                });
            });
        }

        const getFlats = async (page, isReset = false, isLoadMorePostsRequest = false) => {
            if (postsFetchInProgress) {
                return;
            }

            if (
                isLoadMorePostsRequest && notFoundMorePages.classList.contains(ACTIVE_CLASS) ||
                isLoadMorePostsRequest && notFoundFlats.classList.contains(ACTIVE_CLASS)
            ) {
                return;
            }

            postsFetchInProgress = true;
            const selectedFilters = getSelectedFilters();
            const currentPage = parseInt(page);
            const pageToSet = isLoadMorePostsRequest ? (currentPage + 1) : 1;

            try {
                flatsMainWrapper.classList.add(LOADING_RESULTS_CLASS);
                notFoundMorePages.classList.remove(ACTIVE_CLASS);
                notFoundFlats.classList.remove(ACTIVE_CLASS);

                if (isLoadMorePostsRequest) {
                    flatsMainWrapper.classList.add(LOADING_MORE_FLATS);
                }

                const result = await axios.post(API_URL, {
                    page: pageToSet,
                    is_reset: isReset,
                    filters: selectedFilters ?? {}
                });

                if (result.data) {
                    const responseData = result.data;
                    const googleMapsData = responseData.google_maps_data;
                    const postsHtml = responseData.posts_html;
                    const maxPages = responseData.max_num_pages;

                    flatsSearchMap.loadMapElements(googleMapsData, isLoadMorePostsRequest);

                    if (isLoadMorePostsRequest) {
                        if (postsHtml) {
                            searchResultsInner.innerHTML = searchResultsInner.innerHTML + postsHtml;
                        } else {
                            notFoundMorePages.classList.add(ACTIVE_CLASS);
                        }
                    } else {
                        if (postsHtml) {
                            searchResultsInner.innerHTML = postsHtml;
                        } else {
                            searchResultsInner.innerHTML = "";
                            notFoundFlats.classList.add(ACTIVE_CLASS);
                        }
                    }

                    if (parseInt(maxPages) !== currentPage) {
                        loadMoreFlatsTrigger.setAttribute("data-page", pageToSet.toString());
                    } else if (parseInt(maxPages) === currentPage) {
                        notFoundMorePages.classList.add(ACTIVE_CLASS);
                    }

                    window.imagesLazyLoad.update();
                    loadMoreFlatsWatcher.recalculateLocation();

                } else {
                    notFoundFlats.classList.add(ACTIVE_CLASS);
                }
            } catch (e) {
                notFoundFlats.classList.add(ACTIVE_CLASS);
            }

            flatsMainWrapper.classList.remove(LOADING_RESULTS_CLASS);

            if (isLoadMorePostsRequest) {
                flatsMainWrapper.classList.remove(LOADING_MORE_FLATS);
            }

            postsFetchInProgress = false;
        }

        const handleFormSubmit = async (e) => {
            e.preventDefault();
            getFlats(1);
        }

        const handleFormReset = () => {
            getFlats(1, true).then(() => {
                filterBoxes.forEach((box) => {
                    const boxInput = box.querySelectorAll("input");

                    boxInput.forEach((i) => {
                        i.value = null;
                    });

                    box.classList.remove(ACTIVE_CLASS);
                    box.classList.remove("selected");
                });

                typeButtons.forEach((buttonWrap) => {
                    const buttonInput = buttonWrap.querySelector("input");
                    const button = buttonWrap.querySelector(".button");
                    button.classList.remove(ACTIVE_CLASS);
                    buttonInput.checked = false;
                });

                checkFilterBoxes.forEach((filterBox) => {
                    const filterInput = filterBox.querySelector("input");
                    filterInput.checked = false;
                });

                toggleFormButtonsActiveState();
            });
        }

        const isFooterInViewport = () => {
            loadMoreFlatsWatcher.enterViewport(() => {
                const currentPage = loadMoreFlatsTrigger.getAttribute("data-page");
                getFlats(currentPage, false, true);
            });
        }

        const loadFromQueryParams = () => {
            const selectedFilters = getSelectedFilters();

            if (selectedFilters || window.location.href.includes('?')) {
                toggleFormButtonsActiveState();
                getFlats(1);
            }
        }

        const initPageScrips = () => {
            if (mapSearchForm) {

                loadFromQueryParams();
                handleTypesSelection();
                toggleMoreFilters();
                isFooterInViewport();
                filterBoxes.forEach((box) => {
                    boxOptionSelection(box);
                });

                resetButton.addEventListener("click", () => handleFormReset());

                typeButtonsCheckboxes.forEach((checkbox) => {
                    checkbox.addEventListener("change", () => toggleFormButtonsActiveState())
                });

                mapSearchForm.addEventListener("change", () => toggleFormButtonsActiveState());
                mapSearchForm.addEventListener("submit", (e) => handleFormSubmit(e));
            }
        }

        initPageScrips();
    }
}