class FlatSearchGoogleMaps {
    get map() {
        return this._map;
    }

    constructor() {
        this._map = null;
        this.googleMap = null;
        this.markers = [];
        this.elements = [];
    }

    initMap() {
        const mapWrapper = document.getElementById("search_map");
        const google = window.google;
        if (mapWrapper && google) {
            this.googleMaps = google.maps;

            this._map = new this.googleMaps.Map(mapWrapper, {
                zoom: 11,
                disableDefaultUI: true,
                center: new this.googleMaps.LatLng(50.03628571718193, 19.948540643881152),
                styles: [{
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
                }, {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{"color": "#dedede"}, {"lightness": 21}]
                }, {
                    "elementType": "labels.text.stroke",
                    "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
                }, {
                    "elementType": "labels.text.fill",
                    "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
                }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
                }]
            });

            this.loadInitialData();
        }
    }

    loadInitialData() {
        if (window.flatsSearchMapInitialData) {
            this.loadMapElements(JSON.parse(window.flatsSearchMapInitialData));
        }
    }

    loadMapElements(elements, append = false) {
        if (elements.length) {
            if (append) {
                this.elements = this.elements.concat(elements);
            } else {
                this.elements = elements;
                this.markers = [];
            }

            this.clearMarkers();
            this.initializeMarkers();
        }
    }

    initializeMarkers() {
        const map = this.map;
        const googleMaps = this.googleMaps;
        const bounds = new googleMaps.LatLngBounds();

        this.elements.forEach((flat) => {
            const flatPosition = {lat: flat.location.lat, lng: flat.location.lng};
            const flatHtml = this.createInfoWindowHtml(flat);
            const flatBox = new this.googleMaps.InfoWindow({
                content: flatHtml,
            });

            const marker = new this.googleMaps.Marker({
                position: flatPosition,
                map,
                title: flat.title,
                icon: "/wp-content/map-pin.svg"
            });

            this.markers.push(marker);

            marker.addListener("click", () => {
                flatBox.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
            });

            bounds.extend(marker.getPosition());
        });

        map.fitBounds(bounds);
    }

    clearMarkers() {
        if (this.markers.length) {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
        }
        this.markers = [];
    }

    createInfoWindowHtml(flatInfo) {
        const isMobileView = window.innerWidth < 1200;

        const readMoreContent = isMobileView
            ? `<a href="${flatInfo.url}"></a><span>Zobacz więcej <span class="icon-more"></span></span>`
            : `<a href="${flatInfo.url}">Zobacz więcej <span class="icon-more"></span></a>`;

        return `<div class="flat-map-element">
                        <div class="inner">
                            <div class="image">
                                <img src="${flatInfo.image}" alt="${flatInfo.title}">
                            </div>
                            <div class="details">
                                <h3 class="flat-title">${flatInfo.title}</h3>
                                <div class="flat-location">${flatInfo.location.address}</div>
                                <div class="flat-price"><strong>${flatInfo.price}</strong> ${flatInfo.currency}</div>
                                <div class="read-more">${readMoreContent}</div>
                            </div>
                        </div>
                    </div>`;
    }
}

export default FlatSearchGoogleMaps;