import "../styles/index.scss";
import {askForFlat} from "./ask-for-flat";
import LazyLoad from "vanilla-lazyload";
import Popper from 'popper.js';
import {Collapse} from "bootstrap";
import {FilterBoxes} from "./filter-box";
import {attachments} from "./attachments";
import {FlatsSearchPage} from "./flats-search-page";
import SmoothScroll from "./smooth-scrolling";
import {cookieBox} from "./cookie-box";
import {galleryInit} from "./gallery";
import {InvestmentsSingleScripts} from "./investments-single-scripts";
import {latestNews} from "./latest-news";
import {FlatSingleScripts} from "./flat-single-scripts";
import {FlatsMapSearchPage} from "./search-estates-map-page";
const initScripts = () => {
    window.imagesLazyLoad = new LazyLoad();
    FilterBoxes.init();
    SmoothScroll();
    galleryInit();
    FlatSingleScripts();
    InvestmentsSingleScripts();
    FlatsSearchPage();
    FlatsMapSearchPage();
    attachments();
    cookieBox();
    latestNews();
    askForFlat();
    document.body.classList.add("page-loaded");
}

document.addEventListener("DOMContentLoaded", () => {
    initScripts();
});