export const askForFlat = () => {
    const askForFlatBtn = document.querySelectorAll(".ask-for-flat");
    const askForFlatCloseBtn = document.querySelector(".close-ask-form");

    if (askForFlatBtn) {
        askForFlatBtn.forEach((button) => {
            button.addEventListener("click", function () {
                const flatId = button.getAttribute("data-flat-id");
                const flatIdSelect = document.getElementById(flatId);

                if (flatIdSelect) {
                    document.documentElement.classList.add("contact-form-fullscreen");
                    flatIdSelect.selected = true;
                }
            });
        });
    }
    if (askForFlatCloseBtn) {
        askForFlatCloseBtn.addEventListener("click", function () {
            document.documentElement.classList.remove("contact-form-fullscreen");
        });
    }
}