export const attachments = () => {
    const attachButtonWrapper = document.querySelector(".attach-button-wrapper");

    if (attachButtonWrapper) {
        const input = attachButtonWrapper.querySelector("input");
        const selectedFileName = attachButtonWrapper.querySelector(".selected-file-name");

        input.addEventListener("change", () => {
            const filename = input.value;

            if (/^\s*$/.test(filename)) {
                selectedFileName.innerHTML = "Nie wybrano pliku";
            }  else {
                selectedFileName.innerHTML = filename.replace("C:\\fakepath\\", "");
            }
        });
    }
}