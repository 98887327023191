import axios from "axios";
import {FilterBoxes} from "./filter-box";

const API_URL = "/wp-json/v1/filtered_flats";
const ACTIVE_CLASS = "active";
const SELECTED_BOX_CLASS = "selected";
const FILTER_INPROGRESS_CLASS = "filter-in-progress";
const FILTERED_OPTION = "filtered-option";
const MOBILE_BREAKPOINT = 1200;
const investmentsFlatsListing = document.querySelector(".investments-flats-listing");
const noSearchResults = document.querySelector(".investments-flats-listing__empty-results");
const flatsSearchResults = document.querySelector(".investments-flats-listing__results");
const flatsSearchResultsRow = document.querySelector(".investments-flats-listing__results .row");
const filterBoxes = document.querySelectorAll(".filter-box-wrapper");
const loadMoreFlatsButtonWrapper = document.querySelector(".investments-flats-listing__next-page");
const loadMoreFlatsButton = document.querySelector(".investments-flats-listing-load-more");
const toggleMobileActions = document.querySelector(".filters-toggle-mobile");
const filtersCol2 = document.querySelector(".filters-group--col-2");
const mobileSubmitButton = document.querySelector(".filters-submit");
const investmentId = document.querySelector('input[name="investment_id"]');

const isMobileView = window.innerWidth <= MOBILE_BREAKPOINT;

const removeSelectedFilter = (selectedFilterBox) => {
    const input = selectedFilterBox.querySelector("input");
    selectedFilterBox.classList.remove(SELECTED_BOX_CLASS);
    input.value = "";
    createFilterBadges();
    FilterBoxes.showHiddenOptions(prepareOtherFilters);
    FilterBoxes.restoreValue(selectedFilterBox);
    if (!isMobileView) {
        filterResults();
    }

    const selectedFilters = FilterBoxes.getSelectedFilters();

    if (!selectedFilters.length) {
        mobileSubmitButton.disabled = true;
    }
}

const createFilterBadges = () => {
    const selectedFilters = FilterBoxes.getSelectedFilters();
    const selectedFiltersCol = document.querySelector(".selected-filters-col");
    selectedFiltersCol.innerHTML = "";

    selectedFilters.forEach((f) => {
        const dataFilterId = `filter-${f.name}`;
        const selectedFilterBox = document.getElementById(dataFilterId);
        const selectedFilterTitle = selectedFilterBox.querySelector(".label .title").innerHTML;
        const selectedFilterItemLabel = selectedFilterBox.querySelector(".label .selected-option").innerHTML;

        const badge = document.createElement("span");
        badge.classList.add("badge");
        badge.title = "Kliknij aby usunąć";
        badge.innerHTML = `${(selectedFilterTitle.includes("od") || selectedFilterTitle.includes("do")
            ? `${selectedFilterTitle}: ${selectedFilterItemLabel}`
            : `${selectedFilterItemLabel}`)} <i class="fa fa-times" aria-hidden="true"></i>`;
        badge.setAttribute("data-filter-id", dataFilterId);

        badge.addEventListener("click", () => removeSelectedFilter(selectedFilterBox));
        selectedFiltersCol.append(badge);
        return badge.removeEventListener("click", () => removeSelectedFilter(selectedFilterBox));
    });
}

const filterResults = async (page = 1) => {
    investmentsFlatsListing.classList.add(FILTER_INPROGRESS_CLASS);
    const pageToSet = parseInt(page);
    const selectedFilters = FilterBoxes.getSelectedFilters();
    const result = await axios.post(API_URL, {
        page: pageToSet,
        investment_id: investmentId.value,
        filters: selectedFilters
    });

    if (result.data) {
        const postsHtml = result.data.posts_html;

        if (postsHtml) {
            if (pageToSet === 1) {
                flatsSearchResultsRow.innerHTML = postsHtml;
            } else {
                flatsSearchResultsRow.innerHTML = flatsSearchResultsRow.innerHTML + postsHtml;
            }
            window.imagesLazyLoad.update();
            flatsSearchResults.style.display = "block";
            noSearchResults.style.display = "none";
        } else {
            if (loadMoreFlatsButtonWrapper) {
                loadMoreFlatsButtonWrapper.style.display = "none";
            }
            flatsSearchResultsRow.innerHTML = "";
            flatsSearchResults.style.display = "none";
            noSearchResults.style.display = "block";
        }

        const maxNumPages = parseInt(result.data.max_num_pages);

        if (pageToSet === maxNumPages || pageToSet > maxNumPages) {
            if (loadMoreFlatsButtonWrapper) {
                loadMoreFlatsButtonWrapper.style.display = "none";
            }
        } else if (postsHtml) {
            if (loadMoreFlatsButtonWrapper) {
                loadMoreFlatsButton.setAttribute("data-page", (pageToSet).toString());
                loadMoreFlatsButton.removeAttribute("disabled");
                loadMoreFlatsButtonWrapper.style.display = "block";
            }
        }
    }
    investmentsFlatsListing.classList.remove(FILTER_INPROGRESS_CLASS);
}

const prepareOtherFilters = (filterName, filterValue) => {
    const filterValueInt = parseInt(filterValue);

    if (filterName === "area_from") {
        const areaToBox = document.getElementById("filter-area_to");
        const areaToBoxOptions = areaToBox.querySelectorAll(".option");

        areaToBoxOptions.forEach((option) => {
            const optionValue = parseInt(option.getAttribute("data-value"));

            if (optionValue < filterValueInt) {
                option.classList.add(FILTERED_OPTION);
                option.style.display = "none";
            }
        });
    }

    if (filterName === "area_to") {
        const areaFromBox = document.getElementById("filter-area_from");
        const areaFromBoxOptions = areaFromBox.querySelectorAll(".option");

        areaFromBoxOptions.forEach((option) => {
            const optionValue = parseInt(option.getAttribute("data-value"));

            if (optionValue > filterValueInt) {
                option.classList.add(FILTERED_OPTION);
                option.style.display = "none";
            }
        });
    }

    if (filterName === "rooms_from") {
        const roomsToBox = document.getElementById("filter-rooms_to");
        const roomsToBoxOptions = roomsToBox.querySelectorAll(".option");

        roomsToBoxOptions.forEach((option) => {
            const optionValue = parseInt(option.getAttribute("data-value"));

            if (optionValue < filterValueInt) {
                option.classList.add(FILTERED_OPTION);
                option.style.display = "none";
            }
        });
    }

    if (filterName === "rooms_to") {
        const roomsFromBox = document.getElementById("filter-rooms_from");
        const roomsFromBoxOptions = roomsFromBox.querySelectorAll(".option");

        roomsFromBoxOptions.forEach((option) => {
            const optionValue = parseInt(option.getAttribute("data-value"));

            if (optionValue > filterValueInt) {
                option.classList.add(FILTERED_OPTION);
                option.style.display = "none";
            }
        });
    }

    if (filterName === "floor_from") {
        const floorToBox = document.getElementById("filter-floor_to");
        const floorToBoxOptions = floorToBox.querySelectorAll(".option");

        floorToBoxOptions.forEach((option) => {
            const optionValue = parseInt(option.getAttribute("data-rule-value"));

            if (optionValue < filterValueInt) {
                option.classList.add(FILTERED_OPTION);
                option.style.display = "none";
            }
        });
    }

    if (filterName === "floor_to") {
        const floorFromBox = document.getElementById("filter-floor_from");
        const floorFromBoxOptions = floorFromBox.querySelectorAll(".option");

        floorFromBoxOptions.forEach((option) => {
            const optionValue = parseInt(option.getAttribute("data-rule-value"));

            if (optionValue > filterValueInt) {
                option.classList.add(FILTERED_OPTION);
                option.style.display = "none";
            }
        });
    }
}

const boxOptionSelection = (box) => {
    FilterBoxes.onFilterBoxSelection(box, (input, selectedValue) => {
        prepareOtherFilters(input.name, selectedValue);
        createFilterBadges();
        FilterBoxes.showHiddenOptions(prepareOtherFilters);
        mobileSubmitButton.removeAttribute("disabled");
        if (!isMobileView) {
            filterResults();
        }
    });
}

export const filterFlats = () => {
    if (investmentsFlatsListing) {
        filterBoxes.forEach((box) => {
            boxOptionSelection(box);
        });

        mobileSubmitButton.addEventListener("click", () => {
            filterResults().then(() => {
                jQuery([document.documentElement, document.body]).animate({
                    scrollTop: jQuery("#scroll-to-mobile-results").offset().top - 50
                }, 1000);
            });
        });

        toggleMobileActions.addEventListener("click", () => {
            const textMore = toggleMobileActions.getAttribute("data-text-more");
            const textLess = toggleMobileActions.getAttribute("data-text-less");

            if (!filtersCol2.classList.contains(ACTIVE_CLASS)) {
                toggleMobileActions.innerHTML = textLess;
            } else {
                toggleMobileActions.innerHTML = textMore;
            }

            filtersCol2.classList.toggle(ACTIVE_CLASS);
        });

        if (loadMoreFlatsButton) {
            loadMoreFlatsButton.addEventListener("click", () => {
                const pageToSet = parseInt(loadMoreFlatsButton.getAttribute("data-page")) + 1;
                loadMoreFlatsButton.disabled = true;
                filterResults(pageToSet);
            });
        }
    }
}