import "simplebar";
import "simplebar/dist/simplebar.css";
import SimpleBar from "simplebar";

const filterBoxes = document.querySelectorAll(".filter-box-wrapper");
const filtersMask = document.querySelector(".flat-filters-mask");

const MOBILE_BREAKPOINT = 1200;
const isMobileView = window.innerWidth <= MOBILE_BREAKPOINT;

const ACTIVE_CLASS = "active";
const ONLY_INPUT_FILTER_BOX_CLASS = "active";
const FILTERED_OPTION = "filtered-option";
const SELECTED_BOX_CLASS = "selected";

const initSimpleBar = (box) => {
    if (!isMobileView) {
        const optionsList = box.querySelector(".options-list");
        new SimpleBar(optionsList);
    }
}

const getSelectedFilters = () => {
    const filterBoxes = document.querySelectorAll(".filter-box-wrapper");
    const selections = [];

    filterBoxes.forEach((box) => {
        const input = box.querySelector("input");

        selections.push({
            name: input.name,
            value: input.value
        })
    });

    return selections.filter((s) => s.value);
}

const showHiddenOptions = (prepareOtherFiltersCallback) => {
    const selectedFilters = getSelectedFilters();

    filterBoxes.forEach((box) => {
        const options = box.querySelectorAll(".option");
        options.forEach((o) => {
            o.style.display = "block";
            o.classList.remove(FILTERED_OPTION);
        })
    });

    selectedFilters.forEach((f) => prepareOtherFiltersCallback(f.name, f.value));
}

const restoreValue = (box) => {
    const input = box.querySelector(".option-selected-value");
    const selectedLabel = box.querySelector(".selected-option");
    const inputLabel = box.querySelector(".label-input");

    if (input.value) {
        inputLabel.value = selectedLabel.textContent;
    } else {
        inputLabel.value = "";
    }

    showHiddenOptions();
}

const toggleBoxListeners = (box) => {
    if (box.classList.contains(ONLY_INPUT_FILTER_BOX_CLASS)) {
        return;
    }

    const label = box.querySelector(".label");
    label.addEventListener("click", () => {
        if (box.classList.contains(ACTIVE_CLASS)) {
            filtersMask.classList.remove(ACTIVE_CLASS);
            box.classList.remove(ACTIVE_CLASS);
            restoreValue(box);
            return;
        }

        const activeFilterBox = document.querySelector(".filter-box-wrapper.active");

        if (activeFilterBox) {
            activeFilterBox.classList.remove(ACTIVE_CLASS);
        }

        filtersMask.classList.add(ACTIVE_CLASS);
        box.classList.add(ACTIVE_CLASS);
        initSimpleBar(box);
    });
}

const prepareValuesForSuggestion = (value) => {
    return value.toString().toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '');
}

const suggestionsFilter = (box) => {
    const inputLabel = box.querySelector(".label-input");
    const options = box.querySelectorAll(".option");
    inputLabel.addEventListener("keyup", () => {
        const value = prepareValuesForSuggestion(inputLabel.value);

        options.forEach((o) => {
            const isOptionFiltered = o.classList.contains(FILTERED_OPTION);

            if (!isOptionFiltered) {
                const isSuggestion = o.textContent.includes(prepareValuesForSuggestion(value));
                o.style.display = isSuggestion ? "block" : "none";
            }
        });
    });
}

const filterMask = () => {
    filtersMask.addEventListener("click", () => {
        const activeFilterBox = document.querySelector(".filter-box-wrapper.active");
        FilterBoxes.restoreValue(activeFilterBox);
        activeFilterBox.classList.remove(ACTIVE_CLASS);
        filtersMask.classList.remove(ACTIVE_CLASS);
    });
}

const onFilterBoxSelection = (box, callback) => {
    const options = box.querySelectorAll(".option");
    const input = box.querySelector(".option-selected-value");
    const selectedLabel = box.querySelector(".selected-option");
    const inputLabel = box.querySelector(".label-input");

    options.forEach((o) => o.addEventListener("click", () => {
        const selectedValue = o.getAttribute("data-value");
        const selectedOptionText = o.getAttribute("data-label");
        input.value = selectedValue;
        inputLabel.value = selectedOptionText;
        selectedLabel.innerHTML = selectedOptionText;
        box.classList.add(SELECTED_BOX_CLASS);
        box.classList.remove(ACTIVE_CLASS);
        filtersMask.classList.remove(ACTIVE_CLASS);

        callback(input, selectedValue);
    }));
}

const init = () => {
    filterBoxes.forEach((box) => {
        toggleBoxListeners(box);
        suggestionsFilter(box);
    });

    filterMask();
}

export const FilterBoxes = {
    init,
    restoreValue,
    showHiddenOptions,
    getSelectedFilters,
    toggleBoxListeners,
    onFilterBoxSelection,
}