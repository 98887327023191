import Cookies from "js-cookie";

const COOKIE_NAME = "cookie_consent";
const COOKIE_ACTIVE_CLASS = "active";

export const cookieBox = () => {
    const cookieBox = document.querySelector(".cookies-box");

    if (cookieBox) {
        const closeCookieBox = cookieBox.querySelector(".cookie-close-icon");

        const cookieConsentAccepted = Cookies.get(COOKIE_NAME);

        if (!cookieConsentAccepted) {
            cookieBox.classList.add(COOKIE_ACTIVE_CLASS);
        }

        closeCookieBox.addEventListener("click", () => {
            Cookies.set(COOKIE_NAME, 1)
            cookieBox.classList.remove(COOKIE_ACTIVE_CLASS);
        });
    }
}