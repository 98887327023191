import "lightgallery.js/src/js/lightgallery"
import "lightgallery.js/src/css/lightgallery.css"
import "lightgallery.js/src/css/lg-transitions.css"

export const galleryInit = () => {
    const galleryWrapper = document.querySelectorAll('.init-gallery');

    if (galleryWrapper) {
        galleryWrapper.forEach((i) => {
            lightGallery(i, {thumbnail:true, selector: '.gallery-item'});
        });
    }
}