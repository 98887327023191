import {FilterBoxes} from "./filter-box";

const filterBoxes = document.querySelectorAll(".filter-box-wrapper");
const flatsSearchInner = document.querySelector(".flats-search-inner");

const boxOptionSelection = (box) => {
    FilterBoxes.onFilterBoxSelection(box, (input, selectedValue) => {
        return true;
    });
}

const handleSearch = () => {
    if (flatsSearchInner) {
        const submitButton = flatsSearchInner.querySelector(".search-flats-wrapper .button");

        submitButton.addEventListener("click", () => {
            const selectedItems = FilterBoxes.getSelectedFilters();
        });
    }
}

export const initSearchForm = () => {
    filterBoxes.forEach((box) => {
        boxOptionSelection(box);
    });

    handleSearch();
}