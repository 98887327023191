import StickySidebar from "sticky-sidebar";

const stickySidebar = () => {
    new StickySidebar('.flat-sidebar .sidebar__inner', {
        topSpacing: 100,
        bottomSpacing: 20
    });
}

export const FlatSingleScripts = () => {
    const isFlatPage = document.querySelector(".flat-page");

    if (isFlatPage) {
        stickySidebar();
    }
}